import React, { Fragment } from 'react'
import { Global } from '@emotion/react'
import { globalStyles } from '../components/layout.styles'
import SEO from "../components/Seo"
import NavBar from '../components/NavBar3'
import Footer from '../components/Footer'
import Banner from '../images/new/banners/estude-conosco.png'
import Robo from '../images/new/slider/robo.png'
import Bird from '../images/new/icons/bird-2.svg'

import { 
  section4,
  HeaderBanner,
  container,
  content,
  columns,
  column
} from '../components/styles/Base.styles'

import {
  formulario2,
  label,
  btnEnviar
} from '../components/styles/Teste.styles'

const EstudeConosco = props => (
  <Fragment>
    <Global styles={globalStyles} />
    <SEO title="Estude Conosco | Um reino de aprendizado e conhecimento" />
    <NavBar />
    <div css={HeaderBanner}>
      <img src={Banner} alt='' style={{ width: '100%' }} />
    </div>
    <div css={[section4, content]}>
      <div css={container}>
        <h1>Estude Conosco</h1>
        <h2>Aqui na Kingdom ensinamos nossos pequenos a aprender, a escolher e a conquistar autonomia.</h2>
        <p>Por isso, buscamos promover um futuro sem fronteiras para nossos alunos, aplicando ao dia a dia dos nossos pequenos conceitos inclusivos, de cidadania e inovação.<br />
        Ficamos felizes no seu interesse em matricular seu filho na Kingdom! Isso é sinal de compatibilidade de valores, verdade e propósito.<br />
        Este formulário é a porta de entrada para iniciarmos a matrícula do seu filho. Preencha as informações corretamente e logo nossa equipe entrará em contato com você.<br />
        Vale lembrar que este formulário não funciona como reserva de vaga.</p>
        <div css={columns}>
          <div css={column}>
            <div css={formulario2}>
              <form style={{ margin: '0 auto' }}>
                <div css={label}>
                  <input placeholder='Aluno' type="text" name="name" id="name" required />
                </div>
                <div css={label}>
                  <input placeholder='Data de Nascimento' type="text" name="date" id="date" required />
                </div>
                <div css={label}>
                  <input placeholder='Responsável' type="text" name="date" id="date" required />
                </div>
                <div css={label}>
                  <input placeholder='Data provável  ingresso' type="text" name="date" id="date" required />
                </div>
                <div css={label}>
                  <input placeholder='Turno de interesse' type="text" name="email" id="email" required />
                </div>
                <div><img src={Bird} style={{ marginTop: 56, marginBottom: 0, width: 'auto' }}/></div>
                <button type="submit" css={btnEnviar}>ENVIAR</button>
              </form> 
            </div>
          </div>
          <div css={column}>
            <div style={{ height: '100%', display: 'flex', alignItems: 'end', justifyContent: 'end' }}>
              <img src={Robo} alt='' style={{ marginBottom: 0 }}  />
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </Fragment>
)

export default EstudeConosco
